import "./src/styles/global.css"
import "./src/styles/custom.css";
import * as Sentry from "@sentry/gatsby";
/*  export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  // Always scroll to the top on page navigation
  //console.log(location.action)
  //if (location.action === 'PUSH') {
    window.scrollTo(0, 0);
  //}

  return 
}
// gatsby-browser.js
export const onClientEntry = () => {
  if ('scrollRestoration' in window.history) {
    window.history.scrollRestoration = 'manual';
  }
}; */

// Using ES6 export syntax for Gatsby's browser API hooks
/* export const onRouteUpdate = () => {
  // Function to set the cursor style for all elements
  function setCursorStyle(style) {
    const elements = document.querySelectorAll("*");
    elements.forEach(element => {
      element.style.cursor = style;
    });
  }

  // Check if the custom cursor element exists
  const cursor = document.getElementById("customCursor");
  if (cursor) {
    // Adjust the custom cursor position on mouse movement
    document.addEventListener("mousemove", e => {
      cursor.style.left = `${e.pageX}px`;
      cursor.style.top = `${e.pageY}px`;
    });

    // Add or remove 'active' class on links hover
    const links = document.querySelectorAll("a");
    links.forEach(link => {
      link.addEventListener("mouseenter", () => cursor.classList.add("active"));
      link.addEventListener("mouseleave", () => cursor.classList.remove("active"));
    });

    // Hide the default cursor
    setCursorStyle("none");
  }
}; */

// gatsby-browser.js

/* export const onRouteUpdate = ({ location }) => {
  if (!location.hash) {
    window.scrollTo(0, 0);
  }
};

export const onInitialClientRender = () => {
  window.addEventListener('popstate', () => {
    window.scrollTo(0, 0);
  });
}; */



/* export const onRouteUpdate = ({ prevLocation }) => { // Check if this is not the initial page load
  if (prevLocation) { window.scrollTo(0, 0); } 
}; */

export const onRouteUpdate = ({ location }) => {
  if (!location.hash) {
    window.scrollTo(0, 0);
  }
};

export const onInitialClientRender = () => {
  window.addEventListener('popstate', () => {
    window.scrollTo(0, 0);
  });
};

if (typeof window !== "undefined" && !window.__SENTRY_INITIALIZED__) {
  Sentry.init({
      dsn: "https://fa8b382abc5340d88af6731999acf329@o4507541117403136.ingest.de.sentry.io/4507542720086096",
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  }